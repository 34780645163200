import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import ChartROIMain from '../../../../public/static/images/where-your-money-goes-main.roi.svg';
import ChartROIAlt from '../../../../public/static/images/where-your-money-goes-alt.roi.svg';
import ChartUKMain from '../../../../public/static/images/where-your-money-goes-main.uk.svg';
import ChartUKAlt from '../../../../public/static/images/where-your-money-goes-alt.uk.svg';
import DonationImpactIcon from '../../../../public/static/icons/donation-impact.svg';
import CampaignIcon from '../../../../public/static/icons/material-campaign.svg';
import BookIcon from '../../../../public/static/icons/material-menu-book.svg';
import HomeIcon from '../../../../public/static/icons/material-maps-home-work.svg';
import { DEFAULT_COUNTRY_CODE } from '../../../../utils/constants';
import {
  generateClassNameByBehaviorSettings,
  behaviorSettingsProps,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBWhereYourMoneyGoesSVG = ({
  blockHeading = '',
  heading = '',
  subheading,
  description = '',
  image,
  otherPercentagesLabel,
  otherPercentages,
  learnMore = {
    nextLink: null,
    label: '',
  },
  behaviorSettings = null,
  uuid = null,
}) => {
  const [panelOpen, setPanelOpen] = useState(false);

  useEffect(() => {
    const closeOnEsc = (event) => {
      if (panelOpen >= 0 && event.keyCode === 27) {
        setPanelOpen(false);
      }
    };
    document.addEventListener('keydown', closeOnEsc);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('keydown', closeOnEsc);
    };
  }, [panelOpen]);
  return (
    <div
      className={`
      bb bb-where-your-money-goes-svg ${styles['bb-where-your-money-goes-svg']} ${
        panelOpen ? styles['bb-where-your-money-goes-svg-panel-opened'] : ''
      } ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
      id={uuid}
    >
      <div className="container">
        {blockHeading && <h2>{blockHeading}</h2>}
        <div className={styles['bb-where-your-money-goes-svg-container']}>
          <div className={styles['bb-where-your-money-goes-svg-chart']}>
            {DEFAULT_COUNTRY_CODE !== 'GB' && (
              <>
                <ChartROIMain
                  aria-hidden="true"
                  className={styles['bb-where-your-money-goes-svg-chart-main']}
                />
                <ChartROIAlt
                  aria-hidden="true"
                  className={styles['bb-where-your-money-goes-svg-chart-alt']}
                />
              </>
            )}
            {DEFAULT_COUNTRY_CODE === 'GB' && (
              <>
                <ChartUKMain
                  aria-hidden="true"
                  className={styles['bb-where-your-money-goes-svg-chart-main']}
                />
                <ChartUKAlt
                  aria-hidden="true"
                  className={styles['bb-where-your-money-goes-svg-chart-alt']}
                />
              </>
            )}
          </div>
          <div className={styles['bb-where-your-money-goes-svg-info']}>
            <h3>
              {heading}
              <DonationImpactIcon aria-hidden="true" />
              <br />
              <span dangerouslySetInnerHTML={{ __html: subheading }}></span>
            </h3>
            <p>{description}</p>
            <Button
              withArrow
              onClick={() => setPanelOpen(!panelOpen)}
              type={panelOpen ? 'toggled' : 'untoggled'}
              className={styles['bb-where-your-money-goes-svg-panel-toggle']}
              aria-haspopup="true"
              data-analytics={`where-your-money-goes-other-percentages-${
                panelOpen ? 'close' : 'open'
              }`}
            >
              {otherPercentagesLabel}
            </Button>
          </div>
          <img src={image.url} alt={image.alt} width={300} height={300} />
        </div>
        {otherPercentages && !!otherPercentages.length && (
          <div className={styles['bb-where-your-money-goes-svg-panel']}>
            <ul className={styles['bb-where-your-money-goes-svg-items']}>
              {otherPercentages.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`${styles['bb-where-your-money-goes-svg-item']} bb-where-your-money-goes-svg-item`}
                    aria-live="assertive"
                    aria-atomic="true"
                  >
                    <h4>
                      {item.heading}
                      {index === 0 && <CampaignIcon aria-hidden="true" />}
                      {index === 1 && <BookIcon aria-hidden="true" />}
                      {index === 2 && <HomeIcon aria-hidden="true" />}
                    </h4>
                    <h5>{item.subheading}</h5>
                    <p>{item.description}</p>
                  </li>
                );
              })}
            </ul>
            {learnMore.nextLink && (
              <Link {...learnMore.nextLink}>
                <a
                  className={`${styles['bb-where-your-money-goes-svg__learn-more']}`}
                  data-analytics="where-your-money-goes-learn-more"
                >
                  {learnMore.label}
                </a>
              </Link>
            )}
            <Button
              withArrow
              onClick={() => {
                document.getElementById(uuid).scrollIntoView();
                setPanelOpen(!panelOpen);
              }}
              className={styles['bb-where-your-money-goes-svg-panel-toggle']}
              type={panelOpen ? 'toggled' : 'untoggled'}
              aria-haspopup="true"
              data-analytics={`where-your-money-goes-other-percentages-${
                panelOpen ? 'close' : 'open'
              }`}
            >
              {otherPercentagesLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

BBWhereYourMoneyGoesSVG.propTypes = {
  blockHeading: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  otherPercentagesLabel: PropTypes.string.isRequired,
  otherPercentages: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      subheading: PropTypes.string,
      description: PropTypes.string,
    }),
  ).isRequired,
  learnMore: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }),
  uuid: PropTypes.string,
  behaviorSettings: behaviorSettingsProps,
};

export default BBWhereYourMoneyGoesSVG;
